import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Software/Windows/NavButtons';
import ForumBox from 'components/Software/Windows/go1984/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Windows Software for your INSTAR IP Camera",
  "path": "/Software/Windows/go1984/",
  "dateChanged": "2017-12-05",
  "author": "Mike Polinowski",
  "excerpt": "The Windows app go1984 for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features.",
  "image": "./P_SearchThumb_go1984.png",
  "social": "/images/Search/P_SearchThumb_go1984.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-go1984_white.webp",
  "chapter": "Software"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const EuiSpacer = makeShortcode("EuiSpacer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='go1984' dateChanged='2017-12-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='The Windows app go1984 for your PC or Laptop. Control your camera from your computer and harness the computing power for advanced features.' image='/images/Search/P_SearchThumb_go1984.png' twitter='/images/Search/P_SearchThumb_go1984.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Windows/go1984/' locationFR='/fr/Software/Windows/go1984/' crumbLabel="go1984" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "windows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#windows",
        "aria-label": "windows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows`}</h2>
    <h2 {...{
      "id": "logiware-go1984",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#logiware-go1984",
        "aria-label": "logiware go1984 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Logiware go1984`}</h2>
    <p>{`go1984 is software designed for professional and hassle-free monitoring of IP / CCTV cameras. The software works as a so-called hybrid system, which means it is capable of processing digital network cameras as well as analog video sources via video encoders. Companies with analog video technology can use the software to assist the "smart transition" to the digital world while integrating their existing hardware. go1984 thus makes an important contribution to protecting your investments. go1984 `}<a href="http://www.go1984.com/supported-devices.html" target="_blank" rel="noopener noreferrer">{`supports`}</a>{` the hardware of many well-known manufacturers such as, for instance: INSTAR, Axis, Sony, Allnet, Abus, Panasonic, Mobotix, Bosch, Siemens, Basler, Samsung, Grundig and many others. go1984 is available in `}<a href="http://www.go1984.com/software-overview.html" target="_blank" rel="noopener noreferrer">{`various editions`}</a>{` and features everything you'll ever need to carry out professional camera surveillance, including:`}</p>
    <ul>
      <li parentName="ul">{`Live monitoring`}</li>
      <li parentName="ul">{`Recording (timer-controlled, motion-triggered or permanent)`}</li>
      <li parentName="ul">{`Calendar functions`}</li>
      <li parentName="ul">{`Control of PTZ cameras`}</li>
      <li parentName="ul">{`Remote access via the network or Internet via Windows PC, Apple Mac, iOS, Android or Windows Phone device`}</li>
      <li parentName="ul">{`Notification options: acoustic, by e-mail, by voice call via ISDN or by push-notification`}</li>
    </ul>
    <h3 {...{
      "id": "add-an-instar-ip-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-an-instar-ip-camera",
        "aria-label": "add an instar ip camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add an INSTAR IP Camera`}</h3>
    <p>{`To add an IP camera, select the entry `}<strong parentName="p">{`IP cameras`}</strong>{` in the go1984 explorer. A list is displayed showing you pictures of all the available cameras, arranged in alphabetical order. You can use a filter to conveniently restrict the search for the model you want. You can use characters and numbers in any combination. For instance, the text `}<strong parentName="p">{`INSTAR`}</strong>{` will list all the cameras that include this combination of letters in their name.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4a16ce143830a2c4d1413c3ddb9d130d/5a190/Go1984_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACZ0lEQVQoz5XP30vbUBQH8PyRPk7L3kQmgrj2eUJpcQ/OUkWyohZXa1xBN1dMpWl+VXpvsvQm9iZp+ks3K2vR1Opkawvmjtq5jbEN9nk4XO45X+65VKVSscu4eX7e6bgXFxeGoaMhXUfIsizHccrOsGATY4xt29Z1/fj4uOxUSiVMff1yK0tgYwPc3AwIIZr2/t2RefbJ7bhuCWPTtEwTW5bVbDZPT08NQ6/X645TwdhExSLVvXY1o9BofPA8jxBSRCj8fOHtXiqyGAqHwwyzsxRZXIq80IrayUnD9/iRokDLcgxDT+cK1GWrDVXw8azZ7/UIIZWKE4vR7EH6VTwmybJp4tXVaDK51b1yLdteWAhCCPOyiJC+ly1Qt93Pg16/3++Te7VazTKtEsbtdrt73W21WvPP5hmGIYRYdvnp3Jyqqusb64ZhvMkWqB+xkXLZ3t3dcztXACjYtBW1uL2dzHJCyTAAgLGXNFQUQcjl80f7OUiNtiWEjP4sSeKTqSmGYWZnZyGAr5lUrVa9vHRTO6kMe6iqao4fEkVxn1eoXq/neV5/MLi7u/M8D0KY3EqwbIZlDxBCAs9jXIIQqgrMZA6FB9IonE6nI5FINBqNxWIsy6qqms/nZVmWJZnL5QRRzGa5+8d4SZJ+Dy8vL4+Njfl8vunp6bW1NQAAx3HDPs+P5kRRHB34h5ufYZqmx8fHJycn/X7/5uYmAODXob+RJHE/p1ArKysTExMzMzOBQICmaQghx33f8x8EQWBFSMXjcb/fHwwGQ6FQIpFQVVUQBPkeAAAhVPwThJCmaVSj0ahWq7UH9f/xDVyiiayeRYpZAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4a16ce143830a2c4d1413c3ddb9d130d/e4706/Go1984_01.avif 230w", "/en/static/4a16ce143830a2c4d1413c3ddb9d130d/d1af7/Go1984_01.avif 460w", "/en/static/4a16ce143830a2c4d1413c3ddb9d130d/c9875/Go1984_01.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4a16ce143830a2c4d1413c3ddb9d130d/a0b58/Go1984_01.webp 230w", "/en/static/4a16ce143830a2c4d1413c3ddb9d130d/bc10c/Go1984_01.webp 460w", "/en/static/4a16ce143830a2c4d1413c3ddb9d130d/d00b9/Go1984_01.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4a16ce143830a2c4d1413c3ddb9d130d/81c8e/Go1984_01.png 230w", "/en/static/4a16ce143830a2c4d1413c3ddb9d130d/08a84/Go1984_01.png 460w", "/en/static/4a16ce143830a2c4d1413c3ddb9d130d/5a190/Go1984_01.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4a16ce143830a2c4d1413c3ddb9d130d/5a190/Go1984_01.png",
              "alt": "go1984 for your INSTAR IP Camera",
              "title": "go1984 for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Choose your Camera Model and then double-click on the picture of the camera model you want to add.`}</p>
    <h4 {...{
      "id": "add-an-hd-camera-h264",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#add-an-hd-camera-h264",
        "aria-label": "add an hd camera h264 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add an HD Camera (h.264)`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8d4738dac55c83ebae24a3af00347419/5a190/Go1984_02_H264.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.17391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACg0lEQVQoz3XSwUvbUBzA8fyPHoerx3nQsV4UBN3FsyBFMz2I1W1ScB1sTVxb2yTSpklem77k5fW9lCRrEsWxKqsMLUaT0VY3Pex7eO/04ffj8RhqU4xNPwj6/b7jOBBCSinGWNd1Qmm3awOgtVotjLFlWZ0OgRAihCilpmkyN9dXx8f17J42vIkHg1/SuGaz6bouxphS2mg0IIRhGDqOi0zTcRxCKELIgJAZDPpAr/t+mCTJxcWFZVmO4zQajZ3dfdvuep53cPBxfz8LmsBxnZepFyZCltVptvSipDL98x+yUveDMIqiy8tLwzB6vV4+/yn95rVtd13XOzzM5T/n+z/PoWGsrr5VVbVeO9Ga+peqylxf/R5e3wyHw7+TCSGWZWmaihDyPC+XyxWL3+IkQchaXFzUdT2b3W1D42tVYaK7uyRJ4jieYAghIQRjjBAihHiex3NcsVwxDaNWlzc3MgCASrkondQ4UWNub2/jOI7v7ye4CQAhxPd9b9R3x3FURQnC8MPee1GQFEWpVquCIFQEkZcAE0VRHMdRFE2w3tIhhJOnJoRQSpWGLEoSAGqxWBKEqiCI4uga40KhwLLsO5bNZrM8z2uaRgk9DU8DPwj84Oz0TJZljudEQRRH6qEHvLa2NjU1lUql5ufnt7e3EUKu6/YeC4KgXq8fHR2JojTe9znOZDLT09Ozr2YXFhZ2dnYm23bGYYxt237E/8ZOMCcCZn19fWZmJp1OLy0tsSw7/oMj1ul0TNPEFq7VajzPj8CTKlWhdAKYzc2Nubm55eXllZWVra2tcqnEcVyhUBifXLlcFkVR0zRd11vPa7fbDABAlmVlnKaquj567acZ/wkh9AdE05QzdvLy7AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8d4738dac55c83ebae24a3af00347419/e4706/Go1984_02_H264.avif 230w", "/en/static/8d4738dac55c83ebae24a3af00347419/d1af7/Go1984_02_H264.avif 460w", "/en/static/8d4738dac55c83ebae24a3af00347419/c9875/Go1984_02_H264.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8d4738dac55c83ebae24a3af00347419/a0b58/Go1984_02_H264.webp 230w", "/en/static/8d4738dac55c83ebae24a3af00347419/bc10c/Go1984_02_H264.webp 460w", "/en/static/8d4738dac55c83ebae24a3af00347419/d00b9/Go1984_02_H264.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8d4738dac55c83ebae24a3af00347419/81c8e/Go1984_02_H264.png 230w", "/en/static/8d4738dac55c83ebae24a3af00347419/08a84/Go1984_02_H264.png 460w", "/en/static/8d4738dac55c83ebae24a3af00347419/5a190/Go1984_02_H264.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8d4738dac55c83ebae24a3af00347419/5a190/Go1984_02_H264.png",
              "alt": "go1984 for your INSTAR IP Camera",
              "title": "go1984 for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Add a HD Camera - h.264 Stream.`}</p>
    <p>{`You should set the following parameters in it:`}</p>
    <ul>
      <li parentName="ul">{`Name: Give the camera a unique nickname which can be used for it in go1984.`}</li>
      <li parentName="ul">{`IP: Enter the camera’s IP address or host name here - e.g. 192.168.1.106 or abcd12.ddns-instar.de.`}</li>
      <li parentName="ul">{`Port: If a camera port other than the default port "554" (check Network/IP Configuration inside the cameras web user interface for its RTSP port) is being used, you can change this here.`}</li>
      <li parentName="ul">{`PTZ Port: If the selected IP camera is a PTZ camera, you can adjust the port here for camera control - you don´t have to change it for your INSTAR camera.`}</li>
      <li parentName="ul">{`Username: If authentication is required to access the camera, enter your username here. The default for INSTAR cameras is admin`}</li>
      <li parentName="ul">{`Password: Enter your password here for the authentication process mentioned above. Refer to your camera manual for details of the default passwords. The default for INSTAR HD cameras is instar.`}</li>
    </ul>
    <p>{`Finally, click on `}<strong parentName="p">{`OK`}</strong>{` to confirm your details.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/76ff1f3c7661b3b42fc25af4e006fbda/5a190/Go1984_05_H264.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB90lEQVQoz3WSz4viMBSA/Se9i+B/UBAELyJ4dvagoCdRevEos+BBFlwHurFT27RNUlO1o8vWtibKOLv+dlc768zO6nd4kOR97yXkRXTdME1knkAIIYyxZVnnBR45zngyeTozGo0cx5lMJpTS4dAZ2FAF7chwNNQ0+Yfrbrfr5XJJCIEQAgAghIZhWJaFMTHRqbp+RtM0CDVkYg3qkfV6NRiOvkmQkO+Hw9G2bYwxhBBjbBgGhBDdQO31IqvVars/ag8KeHicej6l1DAMQogkSbIsPzmO53lBELiu+97EGKuqGnl+XlAbDwd0vfr18vKTUnp+v9lsNh8VZcYYY7MZY9Pp9IrMmPdV+mIPnM1mczwe+/0+QkhRlEajYZqIcxYEAbslczdwbJtzvtvt9vt9KAMAWq2Wqqq+789OjW/I2/WpYcjhcKCUIoQghPef7yGEi8WCMTafz33fv9aZM8657/mc8/l8TggJ5U6no+v6eDy+/PMVWRTFVCqVy+Xy+Xy9Xn+dFYQsy0IIGX+57P8jF4vFaDSaSCTS6XSlUrkkfcj+wKtcKpXi8bggCNlstlqthgdhvMWbXCgUYrGYIAjJZLJcLr+/qv4f4cy+ybVaLZPJ3N19yufzoii2221ZllW1pygK6HblbhcA0AXdUwBAkiRFUcK6f+Tfao+1ml4T2LUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/76ff1f3c7661b3b42fc25af4e006fbda/e4706/Go1984_05_H264.avif 230w", "/en/static/76ff1f3c7661b3b42fc25af4e006fbda/d1af7/Go1984_05_H264.avif 460w", "/en/static/76ff1f3c7661b3b42fc25af4e006fbda/c9875/Go1984_05_H264.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/76ff1f3c7661b3b42fc25af4e006fbda/a0b58/Go1984_05_H264.webp 230w", "/en/static/76ff1f3c7661b3b42fc25af4e006fbda/bc10c/Go1984_05_H264.webp 460w", "/en/static/76ff1f3c7661b3b42fc25af4e006fbda/d00b9/Go1984_05_H264.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/76ff1f3c7661b3b42fc25af4e006fbda/81c8e/Go1984_05_H264.png 230w", "/en/static/76ff1f3c7661b3b42fc25af4e006fbda/08a84/Go1984_05_H264.png 460w", "/en/static/76ff1f3c7661b3b42fc25af4e006fbda/5a190/Go1984_05_H264.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/76ff1f3c7661b3b42fc25af4e006fbda/5a190/Go1984_05_H264.png",
              "alt": "go1984 for your INSTAR IP Camera",
              "title": "go1984 for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The camera will be added via it´s RTSP video stream.`}</p>
    <p>{`The camera you've added will now appear in the branch "IP cameras" of the go1984 explorer. The basic setup of the camera has now been concluded. go1984 has automatically activated motion detection and recording for this image source. As standard, cameras only record when there is a detected motion. If you want to record images permanently, simply deactivate the motion detection option by clicking on the "Motion detection" option box.`}</p>
    <h4 {...{
      "id": "add-an-hd-camera-mjpeg",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#add-an-hd-camera-mjpeg",
        "aria-label": "add an hd camera mjpeg permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add an HD Camera (MJPEG)`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/47ed8fdb296125fe4f45ebd350d9a84d/5a190/Go1984_02_MJPEG.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACM0lEQVQoz22SS0/bQBRG/YOLVKndo2y7aJd9sGrVlm7YFIgtBeKJHdtjPDgxnhnPjOPXjEFqcfpAFaEKwVUTVCLKWV7p6Pvu1dWEEJRiVdfT6TTLMoxxsoQQIkSa0BA6IAxDzjnGhFIaRVEcx4wxjLE2u7zo9923745ms8X3H99s20YIUUoJIUmSCJGG4YguEUJwzieTCWOMEBJFkfbzokEjt6pU27ZN0yCEVjlRFPEljuOUZfHk6eO9vd00zaSUGNOTKDIA1JovX13flUrN5/OmaTDGp6enOzs7GxuPyrJkjAVHMGHsxfNnvYOe69iUUl3fH4+jz4dQu7z49Xs2u7q6WiXHcZymqes4Hz+8J5RyztHxcVGUnU7HNM3tT9vjaLzZ2YxPTnYPoXZ9fd227c3NTdu25+fnhBDGGCUkjuMkSTgXPnQty37z+uXAsm0L2PZwa+uVbdv7INDm8/k/uWmaIAgY53meZ5Msz/KyLF3P6+o6hBAAYJoAADAcOoMB+CvfSx6PR0mSSCkZY2maMsY8zzMMA6zR75uWNbgvT5fJGGMlVZZleZ5XVQUh1HXdsqx13xr8J69qCyGUUlLKqqrqunZd1zCMh+XVzovFYlWbUpJlWVEU+RIpJYTwYdkMtJV2W3s6DYIAIeT7PkLI8zzf903T1HV9ea07AADG4EhTUtV1XRTF2dmZUso0zW63axhGt6v3ej3LslYPGz7ASIMQDm9xgiC4Ha8RhuHxGnfqaPQHRbmzXxlcddoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/47ed8fdb296125fe4f45ebd350d9a84d/e4706/Go1984_02_MJPEG.avif 230w", "/en/static/47ed8fdb296125fe4f45ebd350d9a84d/d1af7/Go1984_02_MJPEG.avif 460w", "/en/static/47ed8fdb296125fe4f45ebd350d9a84d/c9875/Go1984_02_MJPEG.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/47ed8fdb296125fe4f45ebd350d9a84d/a0b58/Go1984_02_MJPEG.webp 230w", "/en/static/47ed8fdb296125fe4f45ebd350d9a84d/bc10c/Go1984_02_MJPEG.webp 460w", "/en/static/47ed8fdb296125fe4f45ebd350d9a84d/d00b9/Go1984_02_MJPEG.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/47ed8fdb296125fe4f45ebd350d9a84d/81c8e/Go1984_02_MJPEG.png 230w", "/en/static/47ed8fdb296125fe4f45ebd350d9a84d/08a84/Go1984_02_MJPEG.png 460w", "/en/static/47ed8fdb296125fe4f45ebd350d9a84d/5a190/Go1984_02_MJPEG.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/47ed8fdb296125fe4f45ebd350d9a84d/5a190/Go1984_02_MJPEG.png",
              "alt": "go1984 for your INSTAR IP Camera",
              "title": "go1984 for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Add a HD Camera - MJPEG Stream`}</p>
    <p>{`You should set the following parameters in it:`}</p>
    <ul>
      <li parentName="ul">{`Name: Give the camera a unique nickname which can be used for it in go1984.`}</li>
      <li parentName="ul">{`IP: Enter the camera’s IP address or host name here - e.g. 192.168.1.106 or abcd12.ddns-instar.de.`}</li>
      <li parentName="ul">{`Port: If a camera port other than the default port "80" (check Network/IP Configuration inside the cameras web user interface for its web port) is being used, you can change this here.`}</li>
      <li parentName="ul">{`PTZ Port: If the selected IP camera is a PTZ camera, you can adjust the port here for camera control - you don´t have to change it for your INSTAR camera.`}</li>
      <li parentName="ul">{`Username: If authentication is required to access the camera, enter your username here. The default for INSTAR cameras is admin`}</li>
      <li parentName="ul">{`Password: Enter your password here for the authentication process mentioned above. Refer to your camera manual for details of the default passwords. The default for INSTAR VGA cameras is instar.`}</li>
    </ul>
    <p>{`Finally, click on "OK" to confirm your details.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/08d2585722fd68991794f55caf091035/5a190/Go1984_05_MJPEG.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.17391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB2klEQVQoz43RP2/aQBiAcT4oq7eGjQEhMbExILKypEiwuCtCSkgwxArCd6g+n/Hd2bVUavC/BBsrRYWAcQWmNAMDv8HyyXrke/VmDJ0RDTuOE0Wh63qTyQRjDCFECFHKTNM0DIMxRinVjpCiYFVljCkYZ1ard1n+TsiPt8UqSZJnSBod+duT0nxQHl9kSiYp7R9CSPoiy3Im2e/ff6+H3XH/SY6iiFKqqiqlFEgSQoppmrZtu65rWRb5hFKKEMpsNn+m059T01iGQRgudV0nhGAVdx+7siwvFkEQBGEYep53IX4LnBcw+GXNd/FBOh5CqN1u67qxXEZp7LruhXjh+hijV/91u92eYkKGw+HD/T3GeD6fW5Zl27bjOJeuvVpv1pvNx0eSJLvdjjGmaZqu6xACSQLzI9dxL/85juMkSeI43u//x4wxCKGqqr7ve57n+/5sNrsQdzqder3+9e6O53lBED5/Tnd73tOFuFKpZLPZLzc3xWKx1WpRSskVTnGtVuM4Lp/Pl8tlnufJdU5xtVrlOK5QKJRKpWazme752vi2dpvL5UpHjUZjNBrJ6GR8BA4gABIAEsb4PP8hFkWx1+sJgtDv9weDgSiKkiQBANLnmXR0PkIIx+PxX6YysXs1ORh+AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/08d2585722fd68991794f55caf091035/e4706/Go1984_05_MJPEG.avif 230w", "/en/static/08d2585722fd68991794f55caf091035/d1af7/Go1984_05_MJPEG.avif 460w", "/en/static/08d2585722fd68991794f55caf091035/c9875/Go1984_05_MJPEG.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/08d2585722fd68991794f55caf091035/a0b58/Go1984_05_MJPEG.webp 230w", "/en/static/08d2585722fd68991794f55caf091035/bc10c/Go1984_05_MJPEG.webp 460w", "/en/static/08d2585722fd68991794f55caf091035/d00b9/Go1984_05_MJPEG.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/08d2585722fd68991794f55caf091035/81c8e/Go1984_05_MJPEG.png 230w", "/en/static/08d2585722fd68991794f55caf091035/08a84/Go1984_05_MJPEG.png 460w", "/en/static/08d2585722fd68991794f55caf091035/5a190/Go1984_05_MJPEG.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/08d2585722fd68991794f55caf091035/5a190/Go1984_05_MJPEG.png",
              "alt": "go1984 for your INSTAR IP Camera",
              "title": "go1984 for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The camera will be added via it´s MJPEG video stream.`}</p>
    <p>{`The camera you've added will now appear in the branch "IP cameras" of the go1984 explorer. The basic setup of the camera has now been concluded. go1984 has automatically activated motion detection and recording for this image source. As standard, cameras only record when there is a detected motion. If you want to record images permanently, simply deactivate the motion detection option by clicking on the "Motion detection" option box.`}</p>
    <h4 {...{
      "id": "add-an-vga-camera-mjpeg",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#add-an-vga-camera-mjpeg",
        "aria-label": "add an vga camera mjpeg permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add an VGA Camera (MJPEG)`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8c89360e2ceea0873c848ff03e4b5c19/5a190/Go1984_02_VGA.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACeUlEQVQoz42QS0/bQBRG/R/Zooh2wyoLViAkJFAfv6Bp4gpU0lVFWvoKiTFpYoeCxx7bY8djeybNxM4DJAqB8iiQNMQVCemDVc/6Hp2rj6NV6vlOo9k8OjpkjCGEXBf7vo8QIoR8rVZ1QzcMw/N913UJIbZtY4wppY6DucvLs7IMVlfBxY/++fmpJEmKotRqNcaY53mUUlVVLctqt9v1eh1jzBgjhGKMbdvmTs86pq0EYSOKouPjY9d1GWO6rq9l1sIwZIy9eZtZTb/UdRiEwYOHMdM0PY8iZG3KKtc5+AY0pdFo9Xo/T05OKpUKY6y8XX70eGmYr394v57JZDqHBxjjp0+WVAB2vpQNE30sqtzF9/Pu1dV19/p3mRDi+56u6xi7QRC8W1/PZrNRFPmEzM3NmabxKp22K5VPRcD1er0oigaDwUi2bZsQ4nmegzEhJAgCUdzMC1sYO7sKSD5/pmmw+Hlr+8vOhgS5brc7GAxubm6iKOp0OqZpMsba7XYYhGEQMlaHUGu2WpnXa1tiQYOwWCxJQ3LyWO73+6OyZVmO44Rh6Pt+lVJCKNRUSZIMHQqbojRGluVbWRCEZDLJ83w6nc5uZCGExCd7e3utIfv7+6qqbeRyt+dl+b6cSCQmJiZisVg8Hk+lUrZtsxob/RwGYbPRBEDN5/OyXC6VSvdlnn8xOTk5PT09Ozu7srIyXvsOSikAQBAEWf6THcm3g6VSqampqZmZmfn5+UQi4TiO53mui13XcxwHY6woSi6Xk2W59DeSJG5Dbnl5OR6PLywsLC4u8jxfKBRyY0TxbiEINYSQ+S8IIU7X9Z3dXWUIAMAwDOv/qFScX6wolNstuKOOAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8c89360e2ceea0873c848ff03e4b5c19/e4706/Go1984_02_VGA.avif 230w", "/en/static/8c89360e2ceea0873c848ff03e4b5c19/d1af7/Go1984_02_VGA.avif 460w", "/en/static/8c89360e2ceea0873c848ff03e4b5c19/c9875/Go1984_02_VGA.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8c89360e2ceea0873c848ff03e4b5c19/a0b58/Go1984_02_VGA.webp 230w", "/en/static/8c89360e2ceea0873c848ff03e4b5c19/bc10c/Go1984_02_VGA.webp 460w", "/en/static/8c89360e2ceea0873c848ff03e4b5c19/d00b9/Go1984_02_VGA.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8c89360e2ceea0873c848ff03e4b5c19/81c8e/Go1984_02_VGA.png 230w", "/en/static/8c89360e2ceea0873c848ff03e4b5c19/08a84/Go1984_02_VGA.png 460w", "/en/static/8c89360e2ceea0873c848ff03e4b5c19/5a190/Go1984_02_VGA.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8c89360e2ceea0873c848ff03e4b5c19/5a190/Go1984_02_VGA.png",
              "alt": "go1984 for your INSTAR IP Camera",
              "title": "go1984 for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You should set the following parameters in it:`}</p>
    <ul>
      <li parentName="ul">{`Name: Give the camera a unique nickname which can be used for it in go1984.`}</li>
      <li parentName="ul">{`IP: Enter the camera’s IP address or host name here - e.g. 192.168.1.106 or abcd12.ddns-instar.de.`}</li>
      <li parentName="ul">{`Port: If a camera port other than the default port "80" (check Network/IP Configuration inside the cameras web user interface for its web port) is being used, you can change this here.`}</li>
      <li parentName="ul">{`PTZ Port: If the selected IP camera is a PTZ camera, you can adjust the port here for camera control - you don´t have to change it for your INSTAR camera.`}</li>
      <li parentName="ul">{`Username: If authentication is required to access the camera, enter your username here. The default for INSTAR cameras is admin`}</li>
      <li parentName="ul">{`Password: Enter your password here for the authentication process mentioned above. Refer to your camera manual for details of the default passwords. The default for INSTAR VGA cameras is «none».`}</li>
    </ul>
    <p>{`Finally, click on "OK" to confirm your details.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c26764ecc87eff9e778fcbabd56f5dec/5a190/Go1984_05_VGA.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB0ElEQVQoz5XRTYvaQBjAcT+oN/Hm9uRBEOLFD+BBFDz5cvHgdXcPMYKW1pAozmQyk5lM0i5t6kvURGGr1WhS1CJSXNj9H57L8IN5eGKMMULQZDJZr33XdQnGEGqqqgIADIPats05Z4xRSsk5TdMQQpQxpOuxzeYVahATy/c3YRhalkUpgxCapokJ0XUdnyMEX7BhGKdJCAQgFgTBZncY9kD/88jzfc45xpgxNhwOIACWZY3H4+l06jiOcROlFEIY225/O86PF8tc+d5qteKcG4aBsS62xdFotFwuPc/z/dNGd7C/cr8o3e8vPw+HQxiGF4w07fHpkTG2Xq8vfjab3cGeO0cIzubzYL+/YlmWRVEEADjOL9d138Tb183uz26330dRdDweOeeEENM0h8OBqqqO4ywWize/HQTBhUVRFASBaZqEEEopQkjH+Ns527Yty7qDn5+eK5VKtVpttVqSJN0+X297PdL/uFAoxOPxVCqVzWYbjYbxvv7hUqmUTCbT6XQ+n282mx/DxWIxkUhkMhlBEOr1OqX0A7hcLj88fBIEIZfL1Wo1RVEghODc4JyiqKp6mert/ifc7XZFUWy325IkdTqdXq/39R31+31Zlv8C6kKx4ElbA9sAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c26764ecc87eff9e778fcbabd56f5dec/e4706/Go1984_05_VGA.avif 230w", "/en/static/c26764ecc87eff9e778fcbabd56f5dec/d1af7/Go1984_05_VGA.avif 460w", "/en/static/c26764ecc87eff9e778fcbabd56f5dec/c9875/Go1984_05_VGA.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c26764ecc87eff9e778fcbabd56f5dec/a0b58/Go1984_05_VGA.webp 230w", "/en/static/c26764ecc87eff9e778fcbabd56f5dec/bc10c/Go1984_05_VGA.webp 460w", "/en/static/c26764ecc87eff9e778fcbabd56f5dec/d00b9/Go1984_05_VGA.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c26764ecc87eff9e778fcbabd56f5dec/81c8e/Go1984_05_VGA.png 230w", "/en/static/c26764ecc87eff9e778fcbabd56f5dec/08a84/Go1984_05_VGA.png 460w", "/en/static/c26764ecc87eff9e778fcbabd56f5dec/5a190/Go1984_05_VGA.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c26764ecc87eff9e778fcbabd56f5dec/5a190/Go1984_05_VGA.png",
              "alt": "go1984 for your INSTAR IP Camera",
              "title": "go1984 for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The camera you've added will now appear in the branch "IP cameras" of the go1984 explorer. The basic setup of the camera has now been concluded. go1984 has automatically activated motion detection and recording for this image source. As standard, cameras only record when there is a detected motion. If you want to record images permanently, simply deactivate the motion detection option by clicking on the "Motion detection" option box.`}</p>
    <h4 {...{
      "id": "live-video",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#live-video",
        "aria-label": "live video permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Live Video`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/922ff7a6db5badc1d9b526f3da31e40d/5a190/Go1984_03_H264.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAADPElEQVQoz22Ne0gTARzH77+CiCDp5WJtPrCXyKyWryxLk5DKMq2kfIBphmYmkVi2yozEUiqyctd2tLSlvSPS5rbTbfdw1Xa7u+12Zri57RSx0hJcphfL+iPow/ePz+8HX76A0+m00zaO846OjnAc53A47HY7wzCO/8EwDEmSFEWzLEtSNPDt+zeSIt509LpcozzPYxhmtVotFguKm+0OBxWApiiSpgNms9l+n4GPxWIB/JOTP37OdD5DP/SSY+PjBEG43W6vx+NyD/7G7fV6OY7zer0ej2doeNjn83kGB30+X3//R2BiYmKI83k+sV8+j34dG6dpWgVBBsRsMhOwEdd3I50aWHEPfPtW09nRebOxXt3a+urZq/aWtpdPXwNfvo6g73r6+gempqZ4nrfZ6Pqr1x4+fvHoyQulArqngORyZe0FmVqtbmy8fuxIXrMcVCihxhu36+oagM/DIyRp5YaG/H4/z/Mf3lsvnz91H7pbd6a0MD2hva0VRTEFeOf5k9aWB/eVCvmbjo7rDVeO5Ow5VXYUmBj7/mPS7/f7Z2ZmAsuE5VbDxUsnck7v37g3QSgrzW67e7X++EFZ8b7askOVhRn1spNnKgqjRPP279wKzA5OT0/Plt+ZsdS4iLSNIdlJa7dEBWfHi4p3rC5IiUyMXBq3Kqg0NTxjU/i22PA1K+anp8YBGo0GBEGVStXe3tbd3YNjhuKM0MoDobV5ETmJgpwkYUVayOF4cXLkspR1wanRAknoQknYQknIgrStMUBTU1NmZmZRUVFVVZXqQQuOGk5mhVXsXl6WJk6XCtKlwsLta7Kk4uiwJZHiRRvCFkeFC1aKgoPmz92wfj0gl8tzc3PLy8tramrU6ke9qCF/lzBz8+K9CSGxUcs2S0T5WSl7EletFi5Iloq2SQTb4yMSYlaKBXOSk6RAc3NzQUHB6crK6upqCIIIgkCMWhzRmnE9ZtJhCGzGjSYTbOjWmtEeo0EPw5pA9F2wXguAIFhSUlJ99uw5mUypVOI47mCcTmcfw7AUZadpO0lSdvqP2Gwky/YNuFwDAy6WZQGj0ajt6tLpdDq9HoZhFEURFPmLCUH+dZNpVlAUwTDsF3EXFbm5AjltAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/922ff7a6db5badc1d9b526f3da31e40d/e4706/Go1984_03_H264.avif 230w", "/en/static/922ff7a6db5badc1d9b526f3da31e40d/d1af7/Go1984_03_H264.avif 460w", "/en/static/922ff7a6db5badc1d9b526f3da31e40d/c9875/Go1984_03_H264.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/922ff7a6db5badc1d9b526f3da31e40d/a0b58/Go1984_03_H264.webp 230w", "/en/static/922ff7a6db5badc1d9b526f3da31e40d/bc10c/Go1984_03_H264.webp 460w", "/en/static/922ff7a6db5badc1d9b526f3da31e40d/d00b9/Go1984_03_H264.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/922ff7a6db5badc1d9b526f3da31e40d/81c8e/Go1984_03_H264.png 230w", "/en/static/922ff7a6db5badc1d9b526f3da31e40d/08a84/Go1984_03_H264.png 460w", "/en/static/922ff7a6db5badc1d9b526f3da31e40d/5a190/Go1984_03_H264.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/922ff7a6db5badc1d9b526f3da31e40d/5a190/Go1984_03_H264.png",
              "alt": "go1984 for your INSTAR IP Camera",
              "title": "go1984 for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Check your cameras live video and control it´s pan & tilt movement.`}</p>
    <p>{`You can check the cameras live Video by selecting the camera from the side menu. If you experience problems with the frame rate or the stream breaks up - please adjust the video quality of your camera for lower bandwidths. Go to Video/Properties (see Screenshots in the chapter above) to select a stream appropriated for your network environment. E.g. for HD cameras set the parameter Uri from 11 (high quality) to 12 (medium quality) or 13 (low bandwidth). If you are using the MJPEG stream of your HD camera, set it´s -chn from 11 to 12 or 13 respectively. You can also adjust the video parameter inside the camera´s web user interface to fit your needs.`}</p>
    <h4 {...{
      "id": "motion-detection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#motion-detection",
        "aria-label": "motion detection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Motion Detection`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8a1eb6273295f8ef5c9b79e01e4e1311/5a190/Go1984_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAAsTAAALEwEAmpwYAAADXklEQVQoz2XSbUwTdwAG8P+yxUQGETQgKWzCLITNWBFlzG2YuIy4zGQxbqjVsvCSm25BkLEaW6EvFnCm23hzosAsHK2hR3GUjlUcUnq3stK7o72j5CiUXqtBiZUlYyOzkN4tNVn2Yb8Pz6fnw/PhAfMLPobxPnr0eGVlZXl52e32uN1u/DmPh0JRzI6iUy6cpmmSnPZ6vRRF0TTNMHP0jBesrf3pdnt884sbGxscxy3GBGgvEwoGm1u+y9u3t/Dd/IoyicnYjyAmgiBZlo01AgGaogDPcat/RfAJ0miwo78ukCQB6/W9MGwdHftx2HJn2HJ37P7goMnpmu6Db9029JnNFp2u0zTQ33HjJohE/g6FQiH//NNw+I/V1dHR8fMXlJ0Gs8440g0jQ1YUMd+/KK+7jZhPHPvoZPHRunr1KfFxpbKhrl4Fnv7+eBLHHjxcikajPM87MFT7bYseMeuRofbrN7p1cHt7R73sgsV6T1pTrVErdT19nT/06PqMjQ0asLL8ZGaGCj8Jb6yv8zxPTROlJccLCvYfyN8ryhEeOfLhkHn4Vue1nyzDHdfaBkwDvbqemspy5aUvv77SBCJrzyLPIpH1dY7johznwCY+FR/duUMgTEtKTnhJ9EZ299VLTVUnG6tLGmvLVDWl2qb6wlzBB4WiBs1l8HwtF43heJ63jd3N35WWnBi3I2VLSizjoaJd4oNZeVnbTryTXn5I+P6B7P0524oKhFca1cD6s7Wrq6u3twdBEAxzjP9iOvx2alZ6vOi1xIyUl4WChKrDmcUFGYf2pBXlpb+Zk7o7I0mUmfRWrlBzWQ20Wq1EIqk8d04ul/cbBxwTI+/lJmzdDLbHv5CwCSRtflFcmH0wO1mYGrfn1fh9mVtf3ylIjAOvCJJVahVobW2FIEgqlWo0msHBO4TLofjq9BcVH1dC4rKST85ApS1XVbWfl9aclSilZ2Tny+UXKz+rKJbWntVqvwHNzc0QBMlkMoVCYTDofb75hUCIDYaC//L7FwMsywaDATboD7B+f+yDS0thy8j3oK2traqqWhGjhGHY6fzt3sQkTrppiiIJgiRJkiBJgsBxnMCJWa93bo5hmFlm1jfpGQEYho3H2Gw2m91uR1EUwzCn0+lyuab+x/WfKQKf/gfyHfvZvXwTLAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8a1eb6273295f8ef5c9b79e01e4e1311/e4706/Go1984_07.avif 230w", "/en/static/8a1eb6273295f8ef5c9b79e01e4e1311/d1af7/Go1984_07.avif 460w", "/en/static/8a1eb6273295f8ef5c9b79e01e4e1311/c9875/Go1984_07.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8a1eb6273295f8ef5c9b79e01e4e1311/a0b58/Go1984_07.webp 230w", "/en/static/8a1eb6273295f8ef5c9b79e01e4e1311/bc10c/Go1984_07.webp 460w", "/en/static/8a1eb6273295f8ef5c9b79e01e4e1311/d00b9/Go1984_07.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8a1eb6273295f8ef5c9b79e01e4e1311/81c8e/Go1984_07.png 230w", "/en/static/8a1eb6273295f8ef5c9b79e01e4e1311/08a84/Go1984_07.png 460w", "/en/static/8a1eb6273295f8ef5c9b79e01e4e1311/5a190/Go1984_07.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8a1eb6273295f8ef5c9b79e01e4e1311/5a190/Go1984_07.png",
              "alt": "go1984 for your INSTAR IP Camera",
              "title": "go1984 for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`go1984 includes high-performance motion detection based on an optimized image difference process. When motion detection is triggered, the so-called "Motion" signal is activated for this camera. This signal can be used, for instance, to begin recording, to launch an FTP upload or to trigger an alarm. Motion areas are highlighted in the live image with red squares. As soon as the actual value exceeds the specified threshold, the motion signal is triggered. The specified threshold (yellow) and current actual value (green or red) are graphically represented by two coloured bars in the program to make adjustments easier for you. The actual value bar changes from green to red if a motion has been detected. The circle in the "Motion" field also turns red.`}</p>
    <p>{`You'll be shown the live image from the camera, with motion areas being marked by red rectangles. As soon as the actual value surpasses the specified limit value, the motion signal is triggered. The specified limit value (yellow) and the current actual value (green or red) are graphically represented by two coloured bars in the program in order to make adjustment easier for you. The actual value bar changes from green to red if a motion has been detected. The circle in the "Motion" field also lights up in red.`}</p>
    <p>{`It often makes sense to exclude certain areas of the camera image from motion detection. In order to do so, you can draw any kind of mask over the image. Various tools are available for doing so. The mask is displayed on the screen transparently for control purposes. Greyed out areas will be excluded from now on.`}</p>
    <p>{`For normal purposes, motion detection settings do not need to be adjusted more than described above. If the default values don't work perfectly for your needs, you'll find additional setup options on the "Properties" tab. Please check your go1984 user manual for more details.`}</p>
    <h4 {...{
      "id": "storage-management",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#storage-management",
        "aria-label": "storage management permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Storage Management`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f1f864965f36a60306887b890c5fca11/5a190/Go1984_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACHElEQVQoz3WSv0/bQBTH84dmzRg2BhSRiY0BwZyFCmUhfwCoKtQhNlF8vrt3d76fxFJpRUKSMrRNlUTYrmwntKrEZzg96fTR9917V3POKCUmT5PFYvE8nVJKMSYAwBhTJcaY6pRSxjIGxrjgxljGeW25/EUwti7JsjTLMq1jo/V4PE6SxGiNI8wAjDFJkowfxs45rbWUUikJQGt5nv34uQo/R94Nep69WCsAKCWUEsIY45yXXXCllDW2kpVSWmsAqK3Xvx8fvyTWRGg8nc6de8AYj0ajMAyDIPB93/O8ILinhAIFAChjd/LiZRKM7r5+e8rzPE1Taw3nvMgldDgchmHIOZeyeHNFNYit/H06Y4zO5vPNZpOmqTGGiVhpA0D7/X7RrbVKqSrwjV3by9V6tV5vNnmev76+WmsIZRSY4NxaW0X9Z/6V0yytGq5kY0w1JIwxQki9w1a+urrqdDofzs97vZ7neUqpWKpYuwdnuRDOOWvtu8nHx8f1er3ZbLZarW63q7SJCKAwRFGkd7ybfHp62mg09vf3j46OLi8vi/XL4pIS4nlelSl3vNVb+eTkpNFoHBwctNvtbrcLQBAKpVIAMBqFIo6FEAAQxzFCiBBa1VLKQj47O9vb22u324eHhxcXF/eBHyEkpRRCUFp8NVKCS6SU1QqstUKI2u3t7fX19ceSTwU3ntcPgmAwGPi+Pyj5t/B9PygZDod/AOnaqiV0kZOxAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f1f864965f36a60306887b890c5fca11/e4706/Go1984_09.avif 230w", "/en/static/f1f864965f36a60306887b890c5fca11/d1af7/Go1984_09.avif 460w", "/en/static/f1f864965f36a60306887b890c5fca11/c9875/Go1984_09.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f1f864965f36a60306887b890c5fca11/a0b58/Go1984_09.webp 230w", "/en/static/f1f864965f36a60306887b890c5fca11/bc10c/Go1984_09.webp 460w", "/en/static/f1f864965f36a60306887b890c5fca11/d00b9/Go1984_09.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f1f864965f36a60306887b890c5fca11/81c8e/Go1984_09.png 230w", "/en/static/f1f864965f36a60306887b890c5fca11/08a84/Go1984_09.png 460w", "/en/static/f1f864965f36a60306887b890c5fca11/5a190/Go1984_09.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f1f864965f36a60306887b890c5fca11/5a190/Go1984_09.png",
              "alt": "go1984 for your INSTAR IP Camera",
              "title": "go1984 for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`go1984 includes efficient ring storage. This enables you to optimize available hard-drive space allocation to the cameras, thereby ensuring that there is always enough memory space available for new recordings. go1984 is able to delete the oldest recordings automatically after the allotted memory has been used up. You can also select an individual recording directory by clicking on the folder icon in the Storage tab under Recording directory.`}</p>
    <h4 {...{
      "id": "email-notification",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#email-notification",
        "aria-label": "email notification permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Email Notification`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c23a19ec6806dba7598a7d4147f1adcd/5a190/Go1984_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABuUlEQVQoz5XSz2vaYADG8fyhHZ68eVUPy1G7o10OOYsigiD0MijsVqtpIdq8v983eaO+Se0sbhVtQas12dRmG66M9nN+v/Dwvq9GCKGUUUY5F5xzxpgQgnMhhKtUECaUUkEQjEYjKaUaqr6E0L7QhsMBhL3xeLxYLB4fH4DjdDoWAABBiDGmCUII3oEQQgQp4wAibb1eeZ5/3aOz2TyKIkopRkgIQSn1PBkEgUqEYej7PtvaTgTA0Varp+XTM7l0gr6czacYk2vHYZwRTKSUYRgGiX1MKd3XAABtuVzGcfTtdnB6+rXVorI/sO1uu92GELquuz/6r7/j+G5y9+XszO+PpPQwxgih3UW+Xv6J5/MfENlDFcbxZrOJPM/7T3MY399/v7hq3Yxu18+bKHpnfNMPCESu604mk+l0KoRgb/ASV6vVj7p+fPypVCo1m032Ni/x55OTD0dHmUxG1/VarcYYe8ds0zTT6XQulysUCvV6ne+Q5DEPHMaGYaRSqWw2m8/ny+UyIQQAoJTi+BW/R3HOIYRapVIpFoumaRqG0Wg0znesjnVpWd1ut9fr2d0tewclv50x9iv+CfaysTzqm50WAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c23a19ec6806dba7598a7d4147f1adcd/e4706/Go1984_10.avif 230w", "/en/static/c23a19ec6806dba7598a7d4147f1adcd/d1af7/Go1984_10.avif 460w", "/en/static/c23a19ec6806dba7598a7d4147f1adcd/c9875/Go1984_10.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c23a19ec6806dba7598a7d4147f1adcd/a0b58/Go1984_10.webp 230w", "/en/static/c23a19ec6806dba7598a7d4147f1adcd/bc10c/Go1984_10.webp 460w", "/en/static/c23a19ec6806dba7598a7d4147f1adcd/d00b9/Go1984_10.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c23a19ec6806dba7598a7d4147f1adcd/81c8e/Go1984_10.png 230w", "/en/static/c23a19ec6806dba7598a7d4147f1adcd/08a84/Go1984_10.png 460w", "/en/static/c23a19ec6806dba7598a7d4147f1adcd/5a190/Go1984_10.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c23a19ec6806dba7598a7d4147f1adcd/5a190/Go1984_10.png",
              "alt": "go1984 for your INSTAR IP Camera",
              "title": "go1984 for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select the entry "Notification" in the go1984 explorer. You can now enter one or several recipients (separated by semicolons) into the field E-mail address. Adjust the subject line if you wish. The variable "%cameraname%" is automatically replaced by the name of the camera triggering the alarm when an e-mail is sent. You have the option to set a rest period which must elapse between sending two e-mails, by clicking on the clock icon. Simply click on the clock icon and then set the rest period you want.`}</p>
    <h4 {...{
      "id": "ftp-upload",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#ftp-upload",
        "aria-label": "ftp upload permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`FTP Upload`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "800px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9c2005d398eb0f3e9e138629f67a1d78/5a190/Go1984_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACKElEQVQoz42SS2saURSA/Z8ug6smOyELwdDkD7gwuGi6iAQayA/IIqmKwviYmWvu49znjJkZxcSGGDAVfEQtM9OWQKHtx+Vszv0453BORmslBB2Px9Pp87fJhBBCCWEJxhillJQyjUIIznn8gVKpFGUsM59/xwQL6S1Xq91up7WmlAohEEJ3GKcCg9ghhDiOQwhRSgkhML7LvK3Xr/Ol2+oP7+9ns1etNeciiqLhcBgEAQAopQC4bTsAMBqO4kwUhUGIMc4sFov12zryFb1zh6OxMQYAwjCMokhrY4zxfR8A+gjZts0YAHCt9WAwSOXlbrd7ep5UPn25vWFGK0apMV4U63H9MIwAwLZtjDEhFGMMAEGYVJ7PZ0EwGI1GLy9Pq9VKKWO1u5Zlcc5FAk/wPe/e9/xfeJ4Xy8/TScP6OhgEm812u90aY4Tgrut2Ol3HcRFCUsq47X6/1+k4jtPr9RBCQspYnj48SQ4Pj4/L5XKz2UgpUR9xzpXWEkAKoQCAsbiNZFspMpWvr68/n52dn59fXV3VarX36d/xT6SUhJBMqVTKZrP7H/YLhcLFxUU6pfgXP+XT09NcLpfP509OTi4vL9NcvJD/kcvl8t7e3uHhYbFYrFarUkpKqe95kv8NIUQsVyqVg4OD44/HR0dH1Wq13W5blkUwRrbTT0AJLkKu66YHl147YyzTarZub27r9UatVqvX682ERr3RbLY672i34/eebrf7A5kKrBG0lyd0AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9c2005d398eb0f3e9e138629f67a1d78/e4706/Go1984_11.avif 230w", "/en/static/9c2005d398eb0f3e9e138629f67a1d78/d1af7/Go1984_11.avif 460w", "/en/static/9c2005d398eb0f3e9e138629f67a1d78/c9875/Go1984_11.avif 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9c2005d398eb0f3e9e138629f67a1d78/a0b58/Go1984_11.webp 230w", "/en/static/9c2005d398eb0f3e9e138629f67a1d78/bc10c/Go1984_11.webp 460w", "/en/static/9c2005d398eb0f3e9e138629f67a1d78/d00b9/Go1984_11.webp 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9c2005d398eb0f3e9e138629f67a1d78/81c8e/Go1984_11.png 230w", "/en/static/9c2005d398eb0f3e9e138629f67a1d78/08a84/Go1984_11.png 460w", "/en/static/9c2005d398eb0f3e9e138629f67a1d78/5a190/Go1984_11.png 800w"],
              "sizes": "(max-width: 800px) 100vw, 800px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9c2005d398eb0f3e9e138629f67a1d78/5a190/Go1984_11.png",
              "alt": "go1984 for your INSTAR IP Camera",
              "title": "go1984 for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you wish the images from a camera to be sent to an FTP server in addition to being stored locally, complete the following dialogue. First, enter the login data for your FTP server:`}</p>
    <ul>
      <li parentName="ul">{`FTP Server: Domain name or IP of your FTP server, e.g. ftp.online.com`}</li>
      <li parentName="ul">{`Port: FTP port, almost always "21"`}</li>
      <li parentName="ul">{`FTP user ID: Username for logging on to the FTP server`}</li>
      <li parentName="ul">{`FTP password: Password for logging on to the FTP server`}</li>
      <li parentName="ul">{`FTP directory: FTP server subdirectory where you want the images to be saved`}</li>
      <li parentName="ul">{`Passive mode: If your FTP server cannot be run in the active mode, select this option`}</li>
      <li parentName="ul">{`Keep connection: After uploading an image, the connection to the FTP server is kept open. If you prefer the connection to be shut, deactivate this option.`}</li>
    </ul>
    <p>{`You can also choose to resize images before saving them on the FTP server. Activate this option and define the new size of the image using the corresponding fields.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      